import React, { FC, Fragment } from 'react';
import { Spin } from 'antd';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import injectReducer from 'utils/injectReducer';
import reducer, { loadingResources } from './reducer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { ContainerState } from './types';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import styled from 'styles/styled-components';
import selectBrandDetailPage from './selectors';
import BrandInfo from './components/BrandInfo';
import media from 'utils/mediaStyle';
import ProductList from './components/ProductList';
import SimilarBrand from './components/SimilarBrand';
import GroupBrandProductList from './components/GroupBrandProductList';
import translations from 'translations';
import messages from './messages';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import { generateUrlBrandDetail } from 'utils/generateBrandDetailUrl';
import NotFoundBrand from './components/NotFound';
import { selectBreadCrumbDataMarkup } from 'containers/MainLayout/selectors';

const MainContainer = styled.div`
  display: flex;
  background: #ffffff;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;

  ${media.md`
    background: none;
    padding-left: 0;
    padding-right: 0;
  `};
  ${({ status }) => !status && `background: none;`}
`;

export interface IStateProps {
  brandDetailDetail: ContainerState;
  breadCrumbDataMarkup?: string;
}
type Props = IStateProps & RouteComponentProps;
const BrandDetailPage: FC<Props> = (props) => {
  const {
    brandDetailDetail: { brandInfo, groupBrands, loading, similarBrands, localLang, alternateLang },
    breadCrumbDataMarkup,
  } = props;
  const title = `${translations(messages.brand)} ${brandInfo?.name}`;
  const validBrandUrl = generateUrlBrandDetail(brandInfo?.id, brandInfo?.name, localLang);
  const alternateBrandUrl = generateUrlBrandDetail(brandInfo?.id, brandInfo?.name, alternateLang);

  return (
    <Fragment>
      <Helmet>
        <title>{`${title} | KAMEREO`}</title>
        <meta property="og:url" content={renderWithWebDomain(process.env.APP_ENV, validBrandUrl)} />
        <link rel="canonical" href={renderWithWebDomain(process.env.APP_ENV, validBrandUrl)} />
        <link rel="alternate" href={renderWithWebDomain(process.env.APP_ENV, validBrandUrl)} />
        <link rel="alternate" hrefLang={localLang} href={renderWithWebDomain(process.env.APP_ENV, validBrandUrl)} />
        <link
          rel="alternate"
          hrefLang={alternateLang}
          href={renderWithWebDomain(process.env.APP_ENV, alternateBrandUrl)}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={renderWithWebDomain(process.env.APP_ENV, `${generateUrlBrandDetail(brandInfo?.id, brandInfo?.name)}`)}
        />
        <meta name="description" content={`${title} - ${brandInfo?.description}`} />
        <meta name="og:description" content={`${title} - ${brandInfo?.description}`} />
        <meta property="og:title" content={`${title} | KAMEREO`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Kamereo" />
        <meta property="og:image:url" content={brandInfo.logo} />
        <meta property="og:image:secure_url" content={brandInfo.logo} />
        <meta property="og:image:alt" content={title} />
        <script type="application/ld+json">{breadCrumbDataMarkup ?? ''}</script>
      </Helmet>
      <Spin spinning={loading}>
        <MainContainer status={brandInfo?.status}>
          {(!brandInfo.id || brandInfo?.status) && (
            <>
              <BrandInfo brandInfo={brandInfo} groupBrands={groupBrands} />
              <ProductList
                sectionName={brandInfo.id}
                products={brandInfo.id ? groupBrands[brandInfo.id].data : loadingResources}
                totalResults={groupBrands[brandInfo.id]?.totalResults}
              />
              {brandInfo.groupName && Object.keys(groupBrands).length > 1 && (
                <GroupBrandProductList
                  brandId={brandInfo.id}
                  groupBrands={groupBrands}
                  groupName={brandInfo.groupName}
                />
              )}
            </>
          )}
          {!brandInfo?.status && <NotFoundBrand />}
          {similarBrands?.length > 0 && <SimilarBrand lang={localLang} similarBrand={similarBrands} />}
        </MainContainer>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  brandDetailDetail: selectBrandDetailPage(),
  breadCrumbDataMarkup: selectBreadCrumbDataMarkup(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({
  key: 'brandDetailPage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'brandDetailPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(BrandDetailPage);
